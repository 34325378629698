// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-work-place-js": () => import("./../src/templates/WorkPlace.js" /* webpackChunkName: "component---src-templates-work-place-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-js": () => import("./../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

